import IntroductionSlider from './Introduction/IntroductionSlider';
import LocalStorageService from '../shared/Storage/LocalStorageService';
import AuthenticationState from '../shared/Entity/Authentication/AuthenticationState';
import UserState from '../shared/Entity/User/UserState';
import Overlay from '../shared/Component/Overlay/Overlay';
import {setAuthToken} from '../../api/utilities';
import {useAppSelector} from '../../app/hooks';
import logo from '../../img/lean-match-by-rgb.png';
import logoSmall from '../../img/lean-match-rgb.png';
import React, {useEffect, useState} from 'react';
import {Link, NavigateFunction, useNavigate} from 'react-router-dom';

const Header = (): React.JSX.Element => {
    const {user}: UserState = useAppSelector<UserState>(state => state.user);

    const [showIntroductionSliderOverlay, setShowIntroductionSliderOverlay] = useState<boolean>(false);

    const navigate: NavigateFunction = useNavigate();

    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    useEffect((): void => {
        if (localStorage.getItem('welcomeOverlayHidden') === 'true') {
            return;
        }

        setShowIntroductionSliderOverlay(true);
    }, []);

    const handleLogout = (): void => {
        const localStorageService: LocalStorageService = new LocalStorageService();

        localStorageService.removeFromStorage('authentication');

        // TODO - deprecated
        setAuthToken(null);

        navigate('/login');
        navigate(0);
    };

    const onCloseIntroductionSlider = (): void => {
        setShowIntroductionSliderOverlay(false);
    };

    return (
        <header className="bg-white">
            <nav className="navbar navbar-expand-lg navbar-light navigation">
                <Link to="/" className="navbar-brand">
                    <img src={logo} alt="Lean Match by Leerstandslotsen" className="brand-image d-none d-lg-block mt-2 mb-2" />
                    <img src={logoSmall} alt="Lean Match" className="brand-image d-none d-md-block d-lg-none mt-2 mb-2" />
                </Link>
                <div className="ms-auto d-flex align-items-center text-muted">
                {/*
                        TODO: Favorites will be post release feature
                        <button type="button" className="btn p-3 text-black-50">
                            <i className="bi bi-heart-fill text-primary"></i>
                        </button>
                    */}

                    <Link to="/konversationen" type="button" className="btn p-3 position-relative">
                        <i className="bi bi-chat-fill text-secondary"></i>
                        {user !== undefined && user !== null && user.numberOfUnreadConversations > 0 &&
                            <span className="position-absolute top-20 start-20 translate-middle badge rounded-pill bg-accent fs-9px">
                                {user.numberOfUnreadConversations}
                            </span>
                        }
                        <div className="fs-9px mt-1 text-center text-black d-none d-md-block">Konversationen</div>
                    </Link>

                    <div className="dropdown">
                        <button type="button" className="btn p-3 position-relative" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-bell-fill text-secondary"></i>
                            {(authenticatedUser?.user.introComplete === false && localStorage.getItem('welcomeOverlayHidden') === 'true') &&
                                <span className="position-absolute top-15 start-20 translate-middle badge rounded-pill bg-accent fs-9px">
                                    1
                                </span>
                            }
                            <div className="fs-9px mt-1 text-center d-none d-md-block">Mitteilungen</div>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end text-secondary">
                            <li>
                                <span className="dropdown-item" style={{cursor: 'pointer'}} onClick={(): void => setShowIntroductionSliderOverlay(true)}>
                                    <i className="bi bi-rocket-takeoff me-2"></i>Intro erneut zeigen
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="dropdown">
                        <button type="button" className="btn p-3 me-2 position-relative" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-question-circle text-secondary"></i>
                            <div className="fs-9px mt-1 text-center d-none d-md-block">Hilfe</div>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end mw-200px text-secondary">
                            <li className="p-3">
                                <strong>Du brauchst Hilfe?</strong><br/>
                                Wende Dich an David:<br/>
                                <ul className="ps-3">
                                    <li>
                                        <a className="text-secondary" href="mailto:help@llasm.de">help@llasm.de</a>
                                    </li>
                                    <li>
                                        <a className="text-secondary" href="tel:+4921714018846">+49 2171 40188-46</a>
                                    </li>
                                </ul>
                                oder nutze unser Kontaktformular.
                                <a className="btn btn-secondary mt-2" href="https://www.llasm.de/kontakt" target="_blank">Kontakt</a>
                            </li>
                        </ul>
                    </div>
                    <div className="separator-line bg-primary"></div>
                    <div className="dropdown d-flex align-items-center">
                        <button className="btn btn-avatar dropdown-toggle d-flex align-items-center px-3 me-1 ms-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="avatar bg-light text-secondary me-1 p-2">{authenticatedUser?.user.firstName.slice(0,1 ).toUpperCase()}</div>
                            <span className="d-none d-md-inline-block text-secondary text-capitalize fs-6">{authenticatedUser?.user.firstName}</span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end text-secondary">
                            <li>
                                <Link to="/meine-stammdaten" className="dropdown-item">
                                    <i className="bi bi-person-bounding-box me-2"></i>Meine Stammdaten
                                </Link>
                            </li>
                            <li>
                                <button className="dropdown-item text-danger" onClick={handleLogout}>
                                    <i className="bi bi-box-arrow-right me-2"></i>Abmelden
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="separator-line bg-primary"></div>
                    <div className="dropdown text-secondary">
                        <button className="btn me-1 ms-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-list"></i>
                            <div className="fs-9px mt-1 text-center d-none d-md-block">Menü</div>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <Link to="/flaechen" className="dropdown-item"><i className="bi bi-shop me-1"></i> Meine Flächen</Link>
                            </li>
                            <li>
                                <Link to="/marktplatz" className="dropdown-item"><i className="bi bi-bag-heart me-1"></i> Markplatz<sup className="text-secondary">beta</sup></Link>
                            </li>
                            <li>
                                <Link to="/hall-of-inspiration/konzepte" className="dropdown-item"><i className="bi bi-magic me-1"></i> Hall of Inspiration</Link>
                            </li>
                            <li>
                                <Link to="/ansprechpartner" className="dropdown-item"><i className="bi bi-person me-1"></i> Meine Ansprechpartner</Link>
                            </li>
                            <li>
                                <hr/>
                            </li>
                            <li>
                                <Link to="https://www.llasm.de/glossar" target="_blank" className="dropdown-item"><i className="bi bi-book me-1"></i> Glossar</Link>
                            </li>
                            <li>
                                <Link to="https://www.llasm.de/faq" target="_blank" className="dropdown-item"><i className="bi bi-lightbulb me-1"></i> FAQ</Link>
                            </li>
                            <li>
                                <Link to="https://www.llasm.de/kontakt" target="_blank" className="dropdown-item"><i className="bi bi-envelope-at me-1"></i> Kontakt</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Overlay show={showIntroductionSliderOverlay} setShow={setShowIntroductionSliderOverlay}>
                <IntroductionSlider onClose={onCloseIntroductionSlider} />
            </Overlay>
        </header>
    );
};

export default Header;
